
export const LeadColumn = [
  {
    displayName: 'Customer Name',
  },
  {
    displayName: 'Customer Info',
  },

  { 
    displayName: 'Address', 
  },
  {
    displayName: 'Appointment Status',
  },

  {
    displayName: 'Deal Won/Loss',
  },
  {
    displayName: 'Proposal Status',
  },
  {
    displayName: 'Finance Type',
  },
  {
    displayName: 'Finance Company',
  },
  { 
    displayName: 'QC Audit', 
  },
];
